import { captureException } from '@sentry/nextjs'
import { init } from 'next-firebase-auth'

import {
  FirebaseAdminConfig,
  FirebaseConfig,
  USE_FIREBASE_EMULATOR,
} from '../constants'
import { isPreviewEnv, isProductionEnv } from './helpers'

export const authPageURL = '/auth'
export const appPageURL = '/flows'

init({
  appPageURL,
  authPageURL,
  cookies: {
    name: 'ArcadeApp',
    httpOnly: true,
    keys: [
      process.env.COOKIE_SECRET_CURRENT,
      process.env.COOKIE_SECRET_PREVIOUS,
    ],
    maxAge: 12 * 60 * 60 * 24 * 1000, // 12 days
    overwrite: true,
    path: '/',
    sameSite: 'strict',
    secure: isProductionEnv() || isPreviewEnv(),
    signed: isProductionEnv() || isPreviewEnv(),
  },
  debug: !!process.env.DEBUG,
  firebaseAuthEmulatorHost: USE_FIREBASE_EMULATOR
    ? // This needs to point to flow-builder and not the actual auth emulator
      // (localhost:9099) because we proxy from flow-builder to the actual
      // emulator host to circumvent Safari cross-site restrictions.
      'localhost:3000'
    : undefined,
  firebaseAdminInitConfig: {
    credential: {
      clientEmail: FirebaseAdminConfig?.credential.clientEmail || '',
      privateKey: FirebaseAdminConfig?.credential.privateKey || '',
      projectId: FirebaseAdminConfig?.credential.projectId || '',
    },
    databaseURL: FirebaseAdminConfig?.databaseURL || '',
  },
  firebaseClientInitConfig: FirebaseConfig,
  loginAPIEndpoint: '/api/login',
  logoutAPIEndpoint: '/api/logout',
  onLoginRequestError: error => captureException(error),
  onLogoutRequestError: error => captureException(error),
  onTokenRefreshError: error => captureException(error),
  onVerifyTokenError: error => captureException(error),
  useFirebaseAdminDefaultCredential: USE_FIREBASE_EMULATOR,
})
