import { useContext } from 'react'

import { AuthContext } from '../contexts/AuthContext'

const useAccount = () => {
  const account = useContext(AuthContext).account
  return account
}

export default useAccount
